//sticky student hosting
$('#sidebar').stickySidebar({
    topSpacing: 10,
    // bottomSpacing: 60
});
//announcements
$('.toggle-newsletter-button .toggle-link').click(function () {
    $('.toggle-newsletter-button .toggle-link').removeClass('active');
    $(this).addClass('active');
});
$('.toggle-newsletter-button .email-button').click(function () {
    $('.mobile-newsletter').addClass('hide');
    $('.email-newsletter').removeClass('hide');
});
$('.toggle-newsletter-button .mobile-button').click(function () {
    $('.email-newsletter').addClass('hide');
    $('.mobile-newsletter').removeClass('hide');
});

//announcements
$('.attribute-row').hover(function () {
    let rowId = $(this).data('row-id');
    $('.attribute-row[data-row-id="' + rowId + '"]').toggleClass("hovered-row");
    $('.attribute-header[data-row-id="' + rowId + '"]').toggleClass("hovered-row-header");
});

$('.mega-menu-list li').hover(function () {
    let liIndex = $(this).index();
    $('.mega-menu-list li').removeClass('active');
    $(this).addClass('active');
    let contents = $(this).parent().parent().parent().find('.content-div').children();
    contents.removeClass('active');
    contents.eq(liIndex).addClass('active');
});

$(".testimonial-load-more-btn").click(function () {
    let thisBtn = $(this);
    thisBtn.addClass('testimonial-load-in-process');
    let url = thisBtn.data('action');
    let token = thisBtn.data('token');
    let offset = thisBtn.data('offset');
    $.ajax({
        type: "POST",
        url: url,
        tryCount: 0,
        retryLimit: 10,
        data: {_token: token, offset: offset}, // serializes the form's elements.
        success: function (data) {
            data = jQuery.parseJSON(data);
            if (data['status'] === true) {
                var last = $("#buffer-container>.item").last();
                $('#buffer-container').append(data['view']);
                thisBtn.removeClass('testimonial-load-in-process');
                thisBtn.data('offset', data['offset']);
                $([document.documentElement, document.body]).animate({
                    scrollTop: last.offset().top
                }, 0);
            } else {
                thisBtn.removeClass('testimonial-load-in-process');
            }
            if (data['end'] === true) {
                thisBtn.css('display', 'none');
            }
            SvgLib.loadSvg();
        },
        error: function (xhr, textStatus, errorThrown) {
            var this_ajax = this;
            if (textStatus == 'timeout') {
                this.tryCount++;
                if (this.tryCount <= this.retryLimit) {
                    //try again
                    $.ajax(this);
                    return;
                }
                return;
            }
            if (xhr.status == 500) {
                this.tryCount++;
                if (this.tryCount <= this.retryLimit) {
                    //try again
                    setTimeout(function () {
                        $.ajax(this_ajax);
                    }, 1000);
                }
            }
        }
    });
});
$(document).on("click", ".testimonial-like", function () {
    let thisElement = $(this);
    thisElement.addClass('disables_gray_blur');
    let url = thisElement.data('action');
    let id = thisElement.data('id');
    let token = thisElement.data('token');
    $.ajax({
        type: "POST",
        url: url,
        tryCount: 0,
        retryLimit: 10,
        data: {_token: token, id: id}, // serializes the form's elements.
        success: function (data) {
            if (data['status'] === true) {
                thisElement.find('.text').fadeIn();
                thisElement.removeClass('disables_gray_blur');
                thisElement.find('.like-icon-svg').attr('fill', 'red');
                thisElement.find('.like-num').text(parseInt(thisElement.find('.like-num').text()) + 1);
            } else {
                thisElement.removeClass('disables_gray_blur');
                alert('شما قبلا این نظر را پسندیده اید!');
            }
        },
        error: function (xhr, textStatus, errorThrown) {
            var this_ajax = this;
            if (textStatus == 'timeout') {
                this.tryCount++;
                if (this.tryCount <= this.retryLimit) {
                    //try again
                    $.ajax(this);
                    return;
                }
                return;
            }
            if (xhr.status == 500) {
                this.tryCount++;
                if (this.tryCount <= this.retryLimit) {
                    //try again
                    setTimeout(function () {
                        $.ajax(this_ajax);
                    }, 1000);
                }
            }
        }
    });
});


//validate file input
file_upload_color = "rgba(255, 54, 54,0.6)";
$(document).ready(function () {
    $(".daneshjoo-form .submit-form input").click(function () {
        if ($(".daneshjoo-form .file-input #file-upload").val() == "") {
            $(".daneshjoo-form .file-input").css({"border-style": "solid", "border-color": file_upload_color, "border-width": "2px"})
        }
    });
});

function resizeBoxTexts() {
    if ($(".header-box-title").length > 0) {
        if ($(".item-thumbnail").width() != 0) {
            let titleFontSize = $(".item-thumbnail").width() * .06936;
            let subtitleFontSize = $(".item-thumbnail").width() * .02890;
            $(".header-box-title").css('font-size', titleFontSize);
            $(".header-box-subtitle").css('font-size', subtitleFontSize);
        }
    }
}

$(document).ready(function () {
    resizeBoxTexts();
    $(window).resize(function () {
        resizeBoxTexts();
    });
});

$(document).on("change", ".inputfile", function (e) {
    var fileName = '';
    if (this.files && this.files.length > 1) {
        fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
    } else {
        fileName = e.target.value.split('\\').pop();
    }
    $(".input-file-name").parent().removeClass('hidden-info');
    $(".input-file-name").html(fileName);

});
// $('#accordionExample>.accordion-item').on('show.bs.collapse', function () {
//     $([document.documentElement, document.body]).animate({
//         scrollTop: $(this).offset().top
//     }, 0);
//     console.log($(this).offset().top);
// });

$(document).ready(function () {
    $('.close-promotion').click(function () {
        var cookie = document.cookie;
        if (cookie.indexOf('promotionClosed=', 0) == -1) {
            var expiration = new Date();
            expiration.setDate(expiration.getDate() + 1);
            document.cookie = 'promotionClosed=1;expires=' + expiration + ';path=/';
        }
        $('.header-promotion').remove();
    });

    $(document).on('click', '.section-tabs .tab-headers .tab-item', function () {
        let _this = $(this),
            _wrapper = _this.parent().parent().parent()
                .find('.tab-contents .content');

        _wrapper.html('<div class="spinner-loader"></div>')

        $.ajax({
            type: "GET",
            url: _this.data('url'),
            tryCount: 0,
            retryLimit: 10,
            success: function (data) {
                _this.parent().find('li').removeClass('active').addClass('tab-item');
                _this.addClass('active').removeClass('tab-item');
                _wrapper.html(data.html);

                $('.price-table-carousel-1').lightSlider({
                    easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
                    rtl: true,
                    speed: 600,
                    pager: true,
                    autoWidth: true,
                    slideMove: 2,
                    auto: false,
                    pauseOnHover: true,
                    pause: 5000,
                    slideMargin: 10,
                });
            },
            error: function (xhr, textStatus, errorThrown) {

            }
        });
    });
});
