$(document).ready(function () {
    $(".horizon-prev").click(function () {
        event.preventDefault(), $(".content-scroll").animate({scrollLeft: "-=80px"}, "slow")
    }), $(".horizon-next").click(function () {
        event.preventDefault(), $(".content-scroll").animate({scrollLeft: "+=80px"}, "slow")
    }), $(".carousel-branding-logo").lightSlider({
        loop: !0,
        autoWidth: !0,
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        speed: 600,
        rtl: !1,
        slideMove: 1,
        auto: !0,
        pause: 6e3,
        slideMargin: 56,
        pager: !1,
        controls: !1
    });
    var e = $(".about-carousel").lightSlider({
        item: 1,
        loop: !0,
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        rtl: !0,
        speed: 600,
        mode: "fade",
        pager: !0,
        slideMove: 1,
        auto: !0,
        pauseOnHover: !0,
        pause: 5e3
    });
    $(".about-go-to-1").on("click", function () {
        e.goToSlide(1)
    }), $(".about-go-to-2").on("click", function () {
        e.goToSlide(2)
    }), $(".about-go-to-3").on("click", function () {
        e.goToSlide(3)
    }), $(".about-go-to-4").on("click", function () {
        e.goToSlide(4)
    }), $(".about-go-to-5").on("click", function () {
        e.goToSlide(5)
    }), $(".about-go-to-6").on("click", function () {
        e.goToSlide(6)
    });
    $(".feature-carousel").lightSlider({
        item: 4,
        loop: !1,
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        rtl: !1,
        speed: 600,
        pager: !0,
        slideMove: 2,
        autoWidth: !0,
        auto: !1,
        pauseOnHover: !0,
        pause: 5e3,
        slideMargin: 0,
        responsive: [{
            breakpoint: 440,
            settings: {item: 1, slideMove: 1}
        }]
    });
    var i = $(".price-table-carousel-1").lightSlider({
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        rtl: !0,
        speed: 600,
        pager: !0,
        autoWidth: !0,
        slideMove: 2,
        auto: !1,
        pauseOnHover: !0,
        pause: 5e3,
        slideMargin: 10
    }), o = $(".price-table-carousel-2").lightSlider({
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        rtl: !1,
        speed: 600,
        pager: !0,
        autoWidth: !0,
        slideMove: 2,
        auto: !1,
        pauseOnHover: !0,
        pause: 5e3,
        slideMargin: 10
    }), a = $(".price-table-carousel-3").lightSlider({
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        rtl: !1,
        speed: 600,
        pager: !0,
        autoWidth: !0,
        slideMove: 2,
        auto: !1,
        pauseOnHover: !0,
        pause: 5e3,
        slideMargin: 10
    }), t = $(".price-table-carousel-4").lightSlider({
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        rtl: !1,
        speed: 600,
        pager: !0,
        autoWidth: !0,
        slideMove: 2,
        auto: !1,
        pauseOnHover: !0,
        pause: 5e3,
        slideMargin: 10
    }), s = $(".price-table-carousel-5").lightSlider({
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        rtl: !1,
        speed: 600,
        pager: !0,
        autoWidth: !0,
        slideMove: 2,
        auto: !1,
        pauseOnHover: !0,
        pause: 5e3,
        slideMargin: 10
    }), n = $(".price-table-carousel-6").lightSlider({
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        rtl: !1,
        speed: 600,
        pager: !0,
        autoWidth: !0,
        slideMove: 2,
        auto: !1,
        pauseOnHover: !0,
        pause: 5e3,
        slideMargin: 10
    });
    $('[data-toggle="tooltip"]').tooltip(), $(".price-table .nav a").on("click", function () {
        setTimeout(function () {
        }, 100)
    }), $(".nav-price-tab-btn").click(function () {
        setTimeout(function () {
            i.refresh(), o.refresh(), a.refresh(), t.refresh(), s.refresh(), n.refresh()
        }, 100)
    }), $(".feature-carousel > .item").on("click", function () {
        $(".feature-carousel .item").removeClass("on-click"), $(this).addClass("on-click")
    });
    $(".customer-comment-logo").lightSlider({
        loop: !0,
        item: 5,
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        speed: 600,
        verticalHeight: 270,
        vertical: !0,
        slideMove: 1,
        auto: !0,
        pause: 8e3,
        slideMargin: 0,
        pager: !1,
        pauseOnHover: !1,
        controls: !1,
        enableTouch: !1,
        enableDrag: !1,
        freeMove: !1
    });
    var r = $(".customer-comment-text").lightSlider({
        loop: !0,
        item: 1,
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        speed: 600,
        slideMove: 1,
        mode: "fade",
        auto: !0,
        pause: 8e3,
        slideMargin: 0,
        pager: !1,
        pauseOnHover: !1,
        controls: !1,
        enableTouch: !1,
        enableDrag: !1,
        freeMove: !1
    });
    setTimeout(function () {
        r.goToSlide(3)
    }, 100);
    $(".customer-carousel").lightSlider({
        loop: !0,
        item: 1,
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        speed: 600,
        slideMove: 1,
        mode: "fade",
        auto: !0,
        pause: 8e3,
        slideMargin: 0,
        pager: !1,
        pauseOnHover: !1,
        controls: !1,
        enableTouch: !1,
        enableDrag: !1,
        freeMove: !1
    });
    $(".index-layout .nav a").on("click", function () {
        $(this).parent().parent().parent().find(".active").removeClass("active"), $(this).parent().addClass("active")
    }), $(".index-layout .tab-services .toggle-btn").on("click", function () {
        $(this).parent().parent().find(".sub-menu-tab").addClass("active-sub-menu")
    }), $(".index-layout .tab-services .back-to-menu").on("click", function () {
        $(".tab-services .sub-menu-tab").removeClass("active-sub-menu"), $(".tab-services .menu-tab-inner").find(".active").removeClass("active"), $(".tab-services .tab-content .tab-pane").find(".active").removeClass("active"), $(".tab-services .tab-content .tab-pane").find(".show").removeClass("show")
    }), $(".show-more-link").on("click", function () {
        $(this).addClass("hidden-btn"), $(".footer-menu .hidden-item").addClass("active")
    });
    var l = 0, c = $(".main-menu").outerHeight();
    $(window).scroll(function (e) {
        !0
    }), $(window).scroll(function () {
        if (500 < $(this).scrollTop()) {
            var e = $(this).scrollTop();
            if (Math.abs(l - e) <= 50) return;
            l < e && c < e ? ($(".main-menu").removeClass("sticky"), $(".go-up").removeClass("active")) : e + $(window).height() < $(document).height() && ($(".main-menu").addClass("sticky"), $(".go-up").addClass("active")), l = e
        } else $(".main-menu").removeClass("sticky"), $(".go-up").removeClass("active")
    })
});
