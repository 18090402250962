import $ from 'jquery';
window.jQuery = $;
window.$ = $;

require('bootstrap');
require('./js/theme-libs.js');
require('./js/lightslider.js');
require('./js/theme.min.js');
require('./js/sticky-sidebar.min.js');
require('./js/jquery.sticky-sidebar.min.js');
require('./js/star-rate.js');
require('./js/custom.js');
